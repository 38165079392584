export const typeWork = [
    {label:"Очистить", value: null}, 
    {label:"Работаю", value:"Работаю"}, 
    {label:"Не работаю", value:"Не работаю"}, 
    {label:"Предприниматель", value:"Предприниматель"}, 
    {label:"Наемный работник", value:"Наемный работник"}, 
    {label:"Большой начальник", value:"Большой начальник"}, 
    {label:"Властелин мира", value:"Властелин мира"}, 
    {label:"Ищу себя", value:"Ищу себя"}, 
    {label:"Предпочитаю не отвечать", value:"Предпочитаю не отвечать"}, 
]