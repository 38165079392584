import React from 'react'
import { getSingleListing } from "services/getSingleListing"
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import img1 from 'default/frontend/images/why/1.svg'
import img2 from 'default/frontend/images/why/2.svg'
import img3 from 'default/frontend/images/why/3.svg'
import img4 from 'default/frontend/images/why/4.svg'

const Page = () => {

  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {

    getSingleListing('pages', '9f55d4d7-bfdb-4a8a-bb91-257068d899e7').then((res) => {

      setListings(res);
      setLoading(false);
    })
  }, []);

  if (loading) { return 'Loading...' }

  return (
    <div className='page-why'>
      <div className="stub"></div>
      <div className="main-grid">
        <div className="col-12 why-top">
          <h1>
            Почему стоит знакомиться
            именно в <span>HotPal?</span>
          </h1>
          <p>
            Бесчисленное количество приложений для знакомств сегодня доступно на рынке: Баду (Badoo), Тиндер (Tinder), Бамбл (Bumble), Мамба (Mamba), Лавпланет (Loveplanet), Пьюр (Pure), ДругВокруг (DrugVokrug) и многие другие. Если ты ищешь любовь, планируешь свидание или просто хочешь поболтать, тебе нужен сервис, на который можно положиться. Ведь в мире знакомств ничто не бывает просто так. Если ты хочешь познакомиться с новыми людьми, Hotpal предлагает широкий спектр функций, которые помогут сделать невозможное возможным. Знакомства через интернет в отелях или гостиницах - это легко. Узнай тех кто будет останавливаться с тобой по соседству.
          </p>
          <p>
            Мы не будем бросаться пустыми словами и называя себя лучшим бесплатным сайтом знакомств, но мы можем представить тебе доступ в Hotpal и докажем, насколько мы хороши.

          </p>
          <div className="img-why">
            <img src={img1} alt="" />
          </div>


        </div>

      </div>
      <div className="main-grid why-body">
        <div className="col-6 col-xs-12">

          <img src={img2} alt="" />
          <h3>
            Быстрые приглашения
          </h3>
          <p>
            Мы решили упростить твои знакомства и придумали быстрые приглашения: “Бокал в лобби”, “Свидание в номере”, “Бассейн или море” и “Поход в ресторан”. Если ты не знаешь, с какой фразы начать разговор с тем, кто тебе понравился, просто отправь одно из наших быстрых приглашений в чате и жди ответа. В Hotpal мы создали место, где люди легко находят общий язык и заинтересованы друг в друге.
          </p>
          <h3>
            Пройди верификацию

          </h3>
          <p>
            Если у тебя есть подтвержденный профиль, это означает, что на фото на самом деле ты. Если в Hotpal ты встречаешь профиль с синей галочкой, значит, это настоящий человек.

          </p>
          <h3>
            Безграничные возможности
          </h3>
          <p>
            Мы в Hotpal хорошо разбираемся в интернет-знакомствах. В отличие от некоторых сайтов, таких как Теамо (Teamo) и Баду (Badoo), нашей главной целью является предоставление возможности встретить свою вторую половинку в неожиданном месте.

          </p>
          <h3>
            Узнай кто тебя лайкнул
          </h3>
          <p>
            Иногда бывает сложно выделить время для знакомств. В современном ритме жизни это понятно. С помощью Hotpal ты сможешь обратить внимание на тех, кто ставит тебе лайки, и использовать свое время с максимальной выгодой.
          </p>

        </div>
        <div className="col-6 col-xs-12">
          <h3>
            Знакомься в гостиницах и отелях
          </h3>
          <p>
            Если ты часто бываешь в Москве, Питере или других городах и странах и хочешь заводить новых друзей, тогда наш сервис создан для тебя. Hotpal позволит тебе знакомиться не только с людьми из разных городов, но и со всего мира. Hotpal станет твоим незаменимым партнером в путешествиях и рабочих командировках. Заводи знакомства легко, встречай новых людей в отелях и гостиницах.
          </p>
          <h3>
            Знакомства в твоем телефоне
          </h3>
          <p>
            В поисках идеального приложения для знакомств важно найти то, которое перевесит все предыдущие опыты. Однако, выбор между популярными платформами, такими как Тиндер (Tinder), Мамба (Mamba) и Баду (Badoo), не так прост. Знакомство в онлайн-режиме - настоящее приключение, в котором вам нужен надежный партнер. Мы, Hotpal, специализируемся на онлайн-знакомствах в отелях и гостиницах  и готовы стать твоим надежным путеводителем в этом деле.
          </p>
          <img src={img3} alt="" />
          <h3>
            Наш уникальный поиск партнера

          </h3>
          <p>
            Мы стараемся не классифицировать наших пользователей и предлагаем познакомиться с людьми, которых ты не встречал ранее. Например, в отличие от Тиндер (Tinder), Мамбы (Mamba) или Баду (Badoo), наш приоритет в Hotpal - поиск партнеров в отелях и гостиницах по всему миру. Мы верим, что важно давать людям возможность для знакомства в самых необычных местах. В нашем сервисе ты можешь увидеть своих соседей по отелю или гостинице. Каждому следует смело делать первый шаг, независимо от пола, возраста или других характеристик.
          </p>

        </div>
        <div className="col-12">
          <div className="btn-container">
            <Link to="/reg-start" className="btn btn--blue">Присоединиться</Link>
          </div>
        </div>
      </div>

    </div >
  )
}

export default Page