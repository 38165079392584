import Section from "pages/main/Section"

const Main = () => {
  return (
    <>
      <Section /></>
  )
}

export default Main
