import React from 'react'

const Footer = () => {
  return (
    <footer>
      <div className="main-grid">
        <div className="col-xs-12 footer-mobile-text">
          <div className="age-plus">
            +18
          </div>
          <p>
            Если вы ищете любовь, отношения или новых друзей, вам стоит обратить внимание на наш сервис Hotpal. Здесь люди знакомятся в отелях и гостиницах, а значит находясь на отдыхе или в рабочей командировке вы обязательно найдете тех, кто близок вам. Hotpal является удобным сайтом, которым пользуются парни и девушки для знакомств и общения в самых необычных местах.
          </p>
          <p>
            Hotpal поможет вам найти то, что вы ищете. Хотите новые отношения? Нет проблем. Хотите найти новых друзей? Легко. Hotpal - это не только сайт знакомств, но и полноценная социальная сеть, где люди со всего мира встречаются, заводят новых друзей и находят свою любовь.


          </p>

        </div>
        <div className="col-6 col-xs-12">
          <div className="nav">
            <h3> Юридическая информация</h3>
            <ul>
              <li> <a href="/about">О нас</a></li>
              <li> <a href="/intelect">Пользовательское соглашение</a></li>
              <li> <a href="/why">Почему стоит знакомиться именно в HotPal?</a></li>
              <li> <a href="/konf">Политика конфиденциальности Hotpal</a></li>
              <li> <a href="/yslovia">Безопастность</a></li>
              <li> <a href="/politic">Политика Cookie</a></li>
            </ul>
          </div>
        </div>

        <div className="col-6 hidden-xs footer-text">
          <div className="age-plus">
            +18
          </div>
          <p>
            Если вы ищете любовь, отношения или новых друзей, вам стоит обратить внимание на наш сервис Hotpal. Здесь люди знакомятся в отелях и гостиницах, а значит находясь на отдыхе или рабочей командировке вы обязательно найдете тех, кто близок вам. Hotpal является удобным сайтом, которым пользуются парни и девушки для знакомств и общения в самых необычных местах.
          </p>
          <p>
            Hotpal поможет вам найти то, что вы ищете. Хотите новые отношения? Нет проблем. Хотите найти новых друзей? Легко. Hotpal - это не только сайт знакомств, но и полноценная социальная сеть, где люди со всего мира встречаются, заводят новых друзей и находят свою любовь.

          </p>
          <p>*Представленные фото являются моделями и используются для демонстрации.</p>
        </div>
      </div>
      <div className="main-grid footer-bottom">

        <div className="col-6 col-xs-12 ">© 2024 HotPal. Все права защищены.</div>
        <div className="col-6 col-xs-12 footer-copyright">
          <a target='_blank' href="https://www.style-you.ru/">Партнёр проекта Style You</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
