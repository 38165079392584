export const zodiac = [
  {label:"Очистить", value: null}, 
  {label:"Овен", value:"Овен"}, 
  {label:"Телец", value:"Телец"}, 
  {label:"Близнецы", value:"Близнецы"}, 
  {label:"Рак", value:"Рак"}, 
  {label:"Лев", value:"Лев"}, 
  {label:"Дева", value:"Дева"}, 
  {label:"Весы", value:"Весы"}, 
  {label:"Скорпион", value:"Скорпион"}, 
  {label:"Стрелец", value:"Стрелец"}, 
  {label:"Козерог", value:"Козерог"}, 
  {label:"Водолей", value:"Водолей"}, 
  {label:"Рыбы", value:"Рыбы"}, 
];