export const orientation = [
    {label:"Очистить", value: null}, 
    {label:"Гетеросексуал", value:"Гетеросексуал"}, 
    {label:"Гомосексуал", value:"Гомосексуал"}, 
    {label:"Бисексуал", value:"Бисексуал"}, 
    {label:"Асексуал", value:"Асексуал"}, 
    {label:"Демисексуал", value:"Демисексуал"}, 
    {label:"Пансексуал", value:"Пансексуал"}, 
    {label:"Квир", value:"Квир"}, 
    {label:"Ищу себя", value:"Ищу себя"}, 
    {label:"Предпочитаю не отвечать", value:"Предпочитаю не отвечать"}, 
  
]